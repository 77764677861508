import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Link from "gatsby-link";

var Recaptcha = require("react-recaptcha");

export default function Example() {
    const [value, setValue] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState("");
    const [select, setSelect] = useState(null);
    const [address, setAddress] = useState();

    useEffect(() => {
        setValue("");
    }, []);

    const sendForm = async (e) => {
        e.preventDefault();

        const numberOfEmployees = document.getElementById("numberEmployees").value;
        const purchaseTimeframe = document.getElementById("purchase-timeframe").value;

        if (!executeRecaptcha) {
            return;
        }

        if (numberOfEmployees === "0") {
            alert("Please select a valid number of employees.");
            return;
        }

        if (purchaseTimeframe === "None") {
            alert("Please select a valid purchase timeframe.");
            return;
        }

        const result = await executeRecaptcha("homepage");
        setToken(result);
        document.getElementById("captchaResponse").value = result;
        document.getElementById("Contact").submit();
    };

    return (
        <div className="relative bg-white">
            <div className="lg:absolute lg:inset-0">
                <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                    <StaticImage
                        src="../../images/contact-20.jpg"
                        alt="Contact Us"
                        placeholder="fixed"
                        layout="fullWidth"
                        imgStyle={{ overflow: "hidden" }}
                        className="h-56 w-full object-cover lg:absolute lg:h-full"
                        style={{ overflow: "hidden" }}
                        border="5px"
                        aspectRatio={1 / 1}
                        formats={["auto", "webp", "avif"]}
                    />
                </div>
            </div>
            <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-2 lg:grid lg:grid-cols-2">
                <div className="lg:pr-8">
                    <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                        <h1 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Contact Us</h1>
                        <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                            Complete the form below to get started with our bookkeeping services.
                        </p>
                        <form
                            action="https://getform.io/f/4c948eb6-ec54-42e5-a843-b2f819836c18"
                            method="POST"
                            className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                            id="Contact"
                        >
                            <div>
                                <input
                                    type="hidden"
                                    id="captchaResponse"
                                    name="g-recaptcha-response"
                                ></input>
                                <label
                                    htmlFor="first-name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    First Name *
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        required
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        className="block w-full shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div>
                                <label
                                    htmlFor="last-name"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Last Name *
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="last-name"
                                        required
                                        id="last-name"
                                        autoComplete="family-name"
                                        className="block w-full shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Email *
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        required
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="company"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Company
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="company"
                                        id="company"
                                        autoComplete="organization"
                                        className="block w-full shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label htmlFor="exampleInputEmail1">Company Address</label>
                                <GooglePlacesAutocomplete
                                    autocompletionRequest={{}}
                                    apiKey="AIzaSyBnTWkimXgryzzdI3KVPWNQwvtJSrm2pAg"
                                    label="Address"
                                    selectProps={{
                                        value: address,
                                        onChange: (val) => {
                                            setAddress(val);
                                        },
                                        isClearable: true,
                                        placeholder: "Company Address",
                                    }}
                                />
                            </div>
                            <input
                                name="Title"
                                type="hidden"
                                value={
                                    address
                                        ? address.value.structured_formatting.main_text
                                        : address
                                }
                            ></input>

                            <input
                                name="PlaceID"
                                type="hidden"
                                value={address ? address.value.place_id : address}
                            ></input>
                            <input
                                name="AddressFromSuggest"
                                type="hidden"
                                value={address ? address.value.description : address}
                                required
                            ></input>
                            <div className="sm:col-span-2">
                                <div className="flex justify-between">
                                    <label
                                        htmlFor="phone"
                                        required
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Phone *
                                    </label>
                                </div>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="phone"
                                        required
                                        id="phone"
                                        autoComplete="tel"
                                        ariaDescribedBy="phone-description"
                                        className="block w-full shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary border-gray-300 rounded-md"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="method-of-contact"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Preferred Method of Contact
                                </label>
                                <select
                                    id="method-of-contact"
                                    name="method-of-contact"
                                    className=" mt-1 block w-full  py-2 text-base border-gray-300 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm rounded-md"
                                    defaultValue="Email"
                                >
                                    <option>Email</option>
                                    <option>Phone</option>
                                </select>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="method-of-contact"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Purchase Timeframe *
                                </label>
                                <select
                                    required
                                    id="purchase-timeframe"
                                    name="purchase-timeframe"
                                    className=" mt-1 block w-full  py-2 text-base border-gray-300 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm rounded-md"
                                >
                                    <option value="None">Select One</option>
                                    <option value="Immediate">Immediate</option>
                                    <option value="ThisQuarter">This Quarter</option>
                                    <option value="NextQuarter">Next Quarter</option>
                                    <option value="ThisYear">This Year</option>
                                    <option value="Unknown">Unknown</option>
                                </select>
                            </div>
                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="numberEmployees"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Number of Employees *
                                </label>
                                <select
                                    id="numberEmployees"
                                    name="NumberOfEmployees"
                                    className=" mt-1 block w-full  py-2 text-base border-gray-300 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm rounded-md"
                                    required
                                >
                                    <option value="0">Select One</option>
                                    <option value="None">None</option>
                                    <option value="1-10">1-10</option>
                                    <option value="10-20">10-20</option>
                                    <option value="20-50">20-50</option>
                                    <option value="50+">50+</option>
                                </select>
                            </div>

                            <div className="sm:col-span-2">
                                <label
                                    htmlFor="payroll-service"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Require Payroll Services *
                                </label>
                                <select
                                    id="payroll-service"
                                    required
                                    name="payroll-service"
                                    className=" mt-1 block w-full  py-2 text-base border-gray-300 focus:outline-none focus:ring-secondary focus:border-secondary sm:text-sm rounded-md"
                                    defaultValue="Yes"
                                >
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="flex justify-between">
                                    <label
                                        htmlFor="how-can-we-help"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Additional Notes
                                    </label>
                                    <span
                                        id="how-can-we-help-description"
                                        className="text-sm text-gray-500"
                                    >
                                        Max. 500 characters
                                    </span>
                                </div>
                                <div className="mt-1">
                                    <textarea
                                        id="additionalNotes"
                                        name="additionalNotes"
                                        aria-describedby="how-can-we-help-description"
                                        rows={4}
                                        className="block w-full shadow-sm sm:text-sm focus:ring-secondary focus:border-secondary border border-gray-300 rounded-md"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="flex justify-between">
                                    <div>
                                        <input
                                            type="checkbox"
                                            className="mr-2"
                                            id="newsletter"
                                            name="newsletter"
                                            value="newsletter"
                                        ></input>
                                        <label
                                            htmlFor="newsletter"
                                            className=" text-sm font-medium text-gray-700"
                                        >
                                            I agree to receive Certus Bookkeeping Inc's updates and
                                            promotions regarding Certus Bookkeeping Inc's products.
                                            You can withdraw your consent at any time.
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="text-left sm:col-span-2">
                                <p className=" text-sm text-gray-500 mb-4">
                                    We care about the protection of your data. Read our{" "}
                                    <Link
                                        href="#"
                                        to="/privacy"
                                        className="font-medium underline"
                                    >
                                        Privacy Policy.
                                    </Link>
                                </p>

                                <button
                                    onClick={sendForm}
                                    className="contact-submission inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary hover:bg-theme focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
