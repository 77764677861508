import React from "react";
import { Helmet } from 'react-helmet'
export default function Example() {
  return (
    <Helmet htmlAttributes={{
      lang: 'en',
    }}>
      <script type="application/ld+json">
        {`
                  {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://www.certusbookkeeping.com",
                    "name": "Certus Bookkeeping",
                    "contactPoint": {
                      "@type": "ContactPoint",
                      "telephone": "+1-833-204-5460",
                      "contactType": "Customer Support"
                    }
                  }
                `}
      </script>
      <title>Certus Bookkeeping - Contact Us</title>
      <meta name="description" content="Contact a Certus Bookkeeping agent to get started today." />
      <link rel="canonical" href="https://certusbookkeeping.com/contact-us" />
    </Helmet>
  );
}
